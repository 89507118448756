import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
// import { Link } from "@components/Link"
// import { FluidImage, BackgroundFluidImage } from "@components/Image"
import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image"
import * as css from "@css/components/blocks/page/NewsArticle.module.styl"

export default function NewsArticle({data})
{
    // const q = useStaticQuery(graphql`
    //     query {
    //         main01 : file(relativePath: { eq: "indexContent__img__main01@2x.jpg" }) {
    //             childImageSharp {
    //                 fluid(maxWidth: 1440) {
    //                     ...GatsbyImageSharpFluid
    //                 }
    //             }
    //         }
    //         main02 : file(relativePath: { eq: "indexContent__img__main02@2x.jpg" }) {
    //             childImageSharp {
    //                 fluid(maxWidth: 1440) {
    //                     ...GatsbyImageSharpFluid
    //                 }
    //             }
    //         }
    //         main03 : file(relativePath: { eq: "indexContent__img__main03@2x.jpg" }) {
    //             childImageSharp {
    //                 fluid(maxWidth: 1440) {
    //                     ...GatsbyImageSharpFluid
    //                 }
    //             }
    //         }
    //     }
    // `)
    console.log(data)
    const { title, uri, content, date, thumbnail, expect } = data
    return(
        <article className={css.container}>
            <div className={css.inner}>
                <p className={css.info}>
                    <span className={css.date}>{date}</span>
                    {/* <span className={css.category}>{category}</span> */}
                </p>
                <h4 className={css.title}>{title}</h4>
                {
                    thumbnail && <div className={css.thumbnail}>
                        <GatsbyImage image={getImage(thumbnail.node.localFile.childImageSharp.gatsbyImageData)} alt=""/>
                    </div>
                }
                <div className={css.content} dangerouslySetInnerHTML={{__html: content}}/>
            </div>
        </article>
    )
}
