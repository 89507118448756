import React from "react"
import { Link } from "@components/Link"
import { graphql } from "gatsby"
import Seo from "@components/SEO"
import Hero from "@components/blocks/page/Hero"
import { BreadcrumbList } from "@components/BreadcrumbList"
// import { Section, Title, Paragraph, Column } from "@components/blocks/page/Section"
import NewsArticle from "@components/blocks/page/NewsArticle"

import * as css from "@css/components/blocks/page/Content.module.styl"
// import Prism from '@components/Prism'


function NewsPageTemplate({data, path})
{
    // const { html, frontmatter } = data.markdownRemark
	// const { title, date, category, thumbnail } = frontmatter
	const { title, uri, content, date, thumbnail, excerpt } = data.post
	console.log(data)
	let { site_url, image } = data.site.siteMetadata

	// let heroImage = thumbnail || data.main01
    return (
		<>
            <Seo
				title={title}
				desc={excerpt}
				image={ (thumbnail && thumbnail.node.localFile.publicURL) || image }
				site_url={`${site_url}${uri}`}
			/>
            <div className={css.container}>
                <div className={css.inner}>
					<NewsArticle data={data.post}/>
					<div className={css.buttons}>
						<Link className={css.button} to='/information/'>MORE INFORMATION</Link>
					</div>
                </div>
            </div>
        </>
    )
}

export const pageQuery = graphql`
	query NewsBySlug($slug: String!) {
		site {
			siteMetadata {
				title
				desc
				author
				site_url
				image
			}
		}
		post: wpPost(uri: { eq: $slug } ) {
			id
			title
			date(formatString: "YYYY.MM.DD")
			databaseId
			uri
			content
			excerpt
			thumbnail: featuredImage {
				node {
					localFile {
						publicURL
						childImageSharp {
							gatsbyImageData
						}
					}
				}
			}
		}
	}
`
export default NewsPageTemplate
